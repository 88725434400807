import React from "react";
import { HeadFC, graphql, useStaticQuery } from "gatsby";
import { Container, Grid, Typography, Box, Button, useTheme, useMediaQuery } from "@mui/material";
import { GatsbyImage, IGatsbyImageData, ImageDataLike, getImage } from "gatsby-plugin-image";
import { ArrowRight } from "@mui/icons-material";
import LayoutType from "../constants/layout-type";
import Colors from "../constants/colors";


const DigitalSignageDevices = () => {

    const data = useStaticQuery(graphql`
        query {
        allFile(filter: {relativePath: {in: ["chromecast-4k.png", "shield.png", "tcl-tv.png"]}}) {
            edges {
            node {
                relativePath
                childImageSharp {
                    gatsbyImageData(width: 450, layout: CONSTRAINED)
                }
            }
            }
        }
        }
    `);

    const images = data.allFile.edges.reduce((acc: { [x: string]: IGatsbyImageData | undefined; }, edge: { node: { relativePath: string | number; childImageSharp: { gatsbyImageData: ImageDataLike | null; }; }; }) => {
        acc[edge.node.relativePath] = getImage(edge.node.childImageSharp.gatsbyImageData);
        return acc;
    }, {});

    const devices = [
        {
            title: 'SHIELD',
            featuredImage: images['shield.png'],
            description: 'The NVIDIA Shield is a high quality device with support for wifi and built in ethernet. This device has powerful hardware for smooth digital signage displays.',
            priceEstimate: '$220',
            manufacturer: 'NVIDIA',
            purchaseLink: 'https://amzn.to/3Pdd6tk',
            ranking: 1
        },
        {
            title: 'Chromecast 4K',
            featuredImage: images['chromecast-4k.png'],
            description: 'The Google Chromecast is a popular digital signage device that is easy to set up and use. It is a small, affordable device that plugs into the HDMI port of your TV and allows you to run ScreenKeep on the latest version of Google TV. The Chromecast is compatible with a wide range of apps and services, making it a versatile and convenient option for digital signage.',
            priceEstimate: '$40',
            manufacturer: 'Google',
            ethernetAdapter: 'https://amzn.to/3uOSNLS',
            purchaseLink: 'https://amzn.to/3uX1Fz1',
            ranking: 1
        },
        {
            title: '55" With Google TV',
            featuredImage: images['tcl-tv.png'],
            description: 'A high quality, low cost, TV with Google TV built in. No additional device required. When paired with ScreenKeep, this TV is a great option for digital signage with simple web pages. It has built in support for wifi and an ethernet port for a wired connection. With its QLED technology, combined with the \'Screen Keep\' night mode feature, this TV can remain operational overnight with the application running and consumes minimal energy, as pixels displaying pure black are turned off.',
            purchaseLink: 'https://amzn.to/3TpN9sU',
            manufacturer: 'TCL',
            priceEstimate: '370'
        }
    ];

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Box>
            <Container maxWidth="md">
                <Box textAlign="center" mt={20} pb={6} flexDirection="column" flex="1" alignItems="center" alignContent="center">
                    <Typography variant="h1" gutterBottom display="inline-block">
                        Digital Signage Devices
                    </Typography>
                    <Typography variant="body1" align="center" display="inline-block">
                        Explore our curated list of digital signage devices that have been thoroughly tested and compared based on a wide range of factors, such as ease of use, performance, and reliability. Each device featured here has been purchased and evaluated to ensure it meets the highest standards for a digital signage solution without a monthly subscription. Find the perfect device to enhance your display experience and make an informed decision with our comprehensive reviews.
                    </Typography>
                </Box>
            </Container>

            {devices.map((device, index) => (
                <Box
                    key={device.title}
                    style={{
                        backgroundColor: index % 2 === 0 ? Colors.BACKGROUND : Colors.BACKGROUND_DARK,
                        marginLeft: "-20px",
                        marginRight: "-20px",
                    }}
                >
                    <Box py={4} px={4}>
                    <Container maxWidth="lg">
                        <Grid
                            container
                            spacing={4}
                            justifyContent="center"
                            alignItems="center"
                            direction={index % 2 === 0 ? "row" : "row-reverse"}
                        >
                            <Grid item xs={12} md={6} lg={index % 2 === 0 ? 7 : 5} sx={{ textAlign: isMobile ? "center" : index % 2 === 0 ? "left" : "right" }}>
                                {device.featuredImage && (
                                    <GatsbyImage
                                        image={device.featuredImage}
                                        alt={device.title}
                                        style={{ marginLeft: !isMobile && index % 2 === 0 ? "-25px" : "0" }}
                                    />
                                )}
                            </Grid>
                                <Grid item xs={12} md={6} lg={index % 2 === 0 ? 5 : 7} textAlign={isMobile ? "center" : "left"}>
                                    <Typography variant="h4" gutterBottom display="inline-block">
                                        {device.manufacturer} {device.title}
                                    </Typography>
                                    {/*<Box>*/}
                                    {/*    <Typography variant="subtitle1" gutterBottom display="inline-block">*/}
                                    {/*        Ranking: {device.ranking}*/}
                                    {/*    </Typography>*/}
                                    {/*</Box>*/}
                                <Box>
                                <Typography variant="body1" gutterBottom display="inline-block">
                                    {device.description}
                                        </Typography>
                                    </Box>
                                <Box>
                                {/*<Typography variant="body1" gutterBottom display="inline-block">*/}
                                {/*    Price estimate: {device.priceEstimate}*/}
                                {/*        </Typography>*/}
                                {/*    </Box>*/}
                                {/*<Box>*/}
                                <Typography variant="body1" display="inline-block">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        href={device.purchaseLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Buy Now On Amazon
                                        <ArrowRight />
                                    </Button>
                                </Typography>
                                        {device.ethernetAdapter && (
                                            <>
                                                <Box>
                                                    <Typography variant="body1" gutterBottom mt={3} display="inline-block">
                                                        This device supports wireless internet only. Need a wired connection? Get an ethernet adapter:
                                                    </Typography>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        href={device.ethernetAdapter}
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        Ethernet Adapter
                                                        <ArrowRight />
                                                    </Button>
                                                </Box>
                                            </>
                                        )}
                                    </Box>
                            </Grid>
                        </Grid>
                        </Container>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};


DigitalSignageDevices.layoutType = LayoutType.DefaultFullWidth;

export default DigitalSignageDevices;


export const Head: HeadFC = () => (
    <>
        <title>ScreenKeep - Digital Signage Devices</title>
        <meta name="description" content="Subscription free digital signage solution for Android TV. View our list of recommended Android Devices for simple web page based digital signage. Transform any Android TV into a digital signage solution with ease, and display web content seamlessly." />
        <meta name="keywords" content="Screen Keep,ScreenKeep,Digital Signage Devices,Cheap Digital Signage,Free Digital Signage,Web Page Digital Signage,Show Web Page On TV,Digital Signage,Android TV Digital Signage,Web Content,Screen Keep App" />
    </>
)